<template>
  <div style="width: 100%; overflow: hidden">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <v-card class="flex-sm-wrap align-center px-3 py-3" outlined>
          <v-row no-gutters>
            <v-col cols="12" md="6" class="justify-center align-center p-4">
              <v-img src="../assets/mkt.png" alt="Illustration" class="w-100" />
            </v-col>
            <v-col cols="12" md="6" class="p-5 flex">
              <div class="mt-2">
                <h1 class="responsive-text font-weight-bold small-width">
                  {{ title }}
                </h1>

                <p class="small-width">
                  {{ content }}
                </p>
                <p class="font-weight-bold">Subscribe for Ksh. 2,500 only.</p>
                <v-row class="mb-4 ml-1">
                  <v-btn
                    @click="
                      () => {
                        onCallBack();
                      }
                    "
                    color="orange"
                    class="mt-4"
                    >Start Now</v-btn
                  >
                  <v-btn
                    variant="outlined"
                    @click="
                      () => {
                        this.$router.go(-1);
                        onCancel();
                      }
                    "
                    class="mt-4 mx-4"
                    >Cancel</v-btn
                  >
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";

import paystackStoreModule from "@/store/modules/paystack";
import vendorPlan from "@/store/modules/vendorPlan";
const { mapActions: paystackActions } = createNamespacedHelpers(
  "PAYSTACK_SUBSCRIPTION"
);
export default {
  name: "Advert",
  data: () => ({
    advert: false,
    showInitDialog: false,
    authUrl: "",
    loading: false,
    authorizationCode: "",
  }),

  props: {
    title: {
      type: String,
      default: "Unlock Limitless Possibilities: Join Our Membership Today!",
    },
    content: {
      type: String,
      default: ` Gain access to endless opportunities and exclusive benefits by
                  joining our membership today! Enjoy unlimited features and
                  take your experience to the next level. Don't miss out, sign
                  up now!`,
    },
    image: {
      type: String,
      default: "../assets/mkt.png",
    },
    onCallBack: {
      type: Function,
    },
    onCancel: {
      type: Function,
    },
  },

  beforeCreate() {
    if (!this.$store.hasModule("PAYSTACK_SUBSCRIPTION")) {
      this.$store.registerModule("PAYSTACK_SUBSCRIPTION", paystackStoreModule);
    }
  },
};
</script>
<style lang="css">
.responsive-text {
  font-size: 16px; /* Default font size */
}

/* Media query for mobile devices */
@media (max-width: 600px) {
  .responsive-text {
    font-size: 18px; /* Smaller font size for mobile */
  }
}

/* Media query for desktop devices */
@media (min-width: 601px) {
  .responsive-text {
    font-size: 34px; /* Larger font size for desktop */
  }
}
</style>
