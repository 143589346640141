










































































































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import { Business, Membership, Role } from "@/types";
import membershipStoreModule from "@/store/modules/membership";

const { mapActions: membershipActions, mapGetters: membershipGetters } =
  createNamespacedHelpers("MEMBERSHIP_LIST");

export default Vue.extend<any, any, any, any>({
  name: "MembershipList",
  data: () => ({
    membership: undefined as undefined | Membership,
    selectedProgram: "All Programs",
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Price", value: "price" },
      { text: "Period", value: "duration" },
      { text: "Members", value: "members" },
      { text: "Status", value: "status" },
      { text: "Action", value: "action" },
    ],
    options: {} as { page: number },
    deleteDialog: false,
  }),
  watch: {
    role() {
      if (this.role) {
        this.fetchMemberships();
      }
    },
    options: {
      handler() {
        this.fetchMemberships();
      },
      deep: true,
    },
  },
  computed: {
    ...membershipGetters(["membershipPage"]),
    role(): Role {
      return this.$store.getters.role;
    },
  },
  methods: {
    ...membershipActions(["fetchMembershipList", "deleteMembership"]),
    fetchMemberships() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const param = `?businessId=${bid}&page=${this.options.page}`;
        this.fetchMembershipList(param);
      }
    },
    deleteM() {
      this.deleteMembership(this.membership?._id).then((m) => {
        if (m) {
          this.deleteDialog = false;
        }
      });
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("MEMBERSHIP_LIST")) {
      this.$store.registerModule("MEMBERSHIP_LIST", membershipStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("MEMBERSHIP_LIST");
  },
});
