






































































import { Business, Role } from "@/types";
import Vue from "vue";
import { createNamespacedHelpers } from "vuex";
import MembershipModule from "@/store/modules/membership";
import clientStoreModule from "@/store/modules/client";

const { mapGetters: membershipGetters, mapActions: membershipActions } =
  createNamespacedHelpers("_Memberships");

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("MEMBERSHIP_CLIENT_LIST");

export default Vue.extend<any, any, any, any>({
  name: "MembershipSessions",
  data: () => ({
    headers: [
      { text: "Client", value: "client" },
      { text: "Membership", value: "membership" },
      { text: "Persons", value: "quantity" },
      { text: "Session Date", value: "clientMembership" },
    ],
    filters: {
      client: null,
      program: null,
    },
  }),
  computed: {
    ...membershipGetters(["membershipSessionPage", "membershipPage"]),
    ...clientGetters(["clientPage", "getClient"]),
    role(): Role {
      return this.$store.getters.role as Role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
  },
  watch: {
    role() {
      this.loadSessions();
      this.fetchMemberships();
    },
  },
  created() {
    this.loadSessions();
    this.fetchMemberships();
    this.fetchClients();
  },
  methods: {
    ...membershipActions(["fetchMembershipSessions", "fetchMembershipList"]),
    ...clientActions(["fetchClientList"]),
    loadSessions() {
      if (this.role) {
        const q = `?businessId=${this.vendor._id}`;
        this.fetchMembershipSessions(q);
      }
    },
    fetchMemberships() {
      if (this.role) {
        const bid = (this.role.business as Business)._id;
        const param = `?businessId=${bid}`;
        this.fetchMembershipList(param);
      }
    },
    fetchClients() {
      if (this.role) {
        this.fetchClientList(
          `?businessId=${(this.role.business as Business)._id}`
        );
      }
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("_Memberships")) {
      this.$store.registerModule("_Memberships", MembershipModule);
    }
    if (!this.$store.hasModule("MEMBERSHIP_CLIENT_LIST")) {
      this.$store.registerModule("MEMBERSHIP_CLIENT_LIST", clientStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("_Memberships");
    this.$store.unregisterModule("MEMBERSHIP_CLIENT_LIST");
  },
});
