var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","overflow":"hidden"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{staticClass:"flex-sm-wrap align-center px-3 py-3",attrs:{"outlined":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"justify-center align-center p-4",attrs:{"cols":"12","md":"6"}},[_c('v-img',{staticClass:"w-100",attrs:{"src":require("../assets/mkt.png"),"alt":"Illustration"}})],1),_c('v-col',{staticClass:"p-5 flex",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"mt-2"},[_c('h1',{staticClass:"responsive-text font-weight-bold small-width"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('p',{staticClass:"small-width"},[_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('p',{staticClass:"font-weight-bold"},[_vm._v("Subscribe for Ksh. 2,500 only.")]),_c('v-row',{staticClass:"mb-4 ml-1"},[_c('v-btn',{staticClass:"mt-4",attrs:{"color":"orange"},on:{"click":function () {
                      _vm.onCallBack();
                    }}},[_vm._v("Start Now")]),_c('v-btn',{staticClass:"mt-4 mx-4",attrs:{"variant":"outlined"},on:{"click":function () {
                      this$1.$router.go(-1);
                      _vm.onCancel();
                    }}},[_vm._v("Cancel")])],1)],1)])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }