





























































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import membershipProgramStoreModule from "@/store/modules/clientMembership";
import { Membership } from "@/types";
import MembershipProgramForm from "./MembershipProgramForm.vue";

const {
  mapActions: membershipProgramActions,
  mapGetters: membershipProgramGetters,
} = createNamespacedHelpers("MEMBERSHIP_PROGRAM_LIST");

export default Vue.extend<any, any, any, any>({
  components: { MembershipProgramForm },
  name: "MembershipProgramList",
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
  },
  data: () => ({
    selectedClient: "All clients",
    headers: [
      {
        text: "Members",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Start date", value: "subscribedOn" },
      { text: "Expires on", value: "expiresOn" },
      { text: "Status", value: "status" },
    ],
    options: {} as { page: number },
    showClientMembershipDialog: false,
    clientId: "",
    subscriptions: [],
  }),
  watch: {
    options: {
      handler() {
        this.fetchMemberships();
      },
      deep: true,
    },
  },
  created() {
    this.fetchMemberships();
  },
  computed: {
    ...membershipProgramGetters(["clientMembershipPage"]),
  },
  methods: {
    ...membershipProgramActions([
      "fetchClientMembershipList",
      "updateClientMembership",
      "deleteClientMembership",
    ]),
    fetchMemberships() {
      const page = this.options.page || 1;
      const id = this.membership._id;
      const params = `?membershipId=${id}&page=${page}&status=&paymentStatus=`;
      this.fetchClientMembershipList(params);
    },
    closeModal() {
      this.fetchMemberships();
      this.showClientMembershipDialog = false;
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("MEMBERSHIP_PROGRAM_LIST")) {
      this.$store.registerModule(
        "MEMBERSHIP_PROGRAM_LIST",
        membershipProgramStoreModule
      );
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("MEMBERSHIP_PROGRAM_LIST");
  },
});
