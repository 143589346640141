var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"400px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[(_vm.membership)?_c('v-card',[_c('v-card-title',[_vm._v("Delete "+_vm._s(_vm.membership.name))]),_c('v-card-text',[_vm._v(" This is an irreversible action. Are you sure you want to delete the membership? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.deleteM}},[_vm._v("Delete")])],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-field",attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")]),_c('span',[_c('span',{staticClass:"text-grey"},[_vm._v("Sort by: ")]),_vm._v(" "+_vm._s(_vm.selectedProgram)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All Programs']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedProgram = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('div',{staticClass:"extra-btns"},[_c('v-btn',{attrs:{"elevation":"0","to":"/membership/new"}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Create New Program ")],1),_vm._v("   "),_c('v-btn',{attrs:{"elevation":"0"}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.membershipPage.docs,"options":_vm.options,"server-items-length":_vm.membershipPage.total,"no-data-text":"No sale products to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(((item.duration.duration) + " " + (item.duration.duration_type)))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.price ? item.price.lumpsum : "")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'active')?_c('v-chip',{attrs:{"color":"#d3f9db"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]):_vm._e(),(item.status === 'inactive')?_c('v-chip',{attrs:{"color":"#facccc"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){return _vm.$emit('select', item)}}},[_vm._v(" View ")]),_vm._v("   "),_c('v-btn',{attrs:{"color":"red","icon":"","rounded":""},on:{"click":function($event){_vm.membership = item;
          _vm.deleteDialog = true;}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1),_vm._v("   "),_c('v-btn',{attrs:{"to":("/membership/" + (item._id))}},[_vm._v("Edit")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }