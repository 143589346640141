



































































































































import Vue, { PropType } from "vue";
import { createNamespacedHelpers } from "vuex";

import orderStoreModule from "@/store/modules/order";
import clientStoreModule from "@/store/modules/client";
import { Business, Client, Membership, Role, User } from "@/types";
import { DATE_REGEX } from "@/util/constants";
import paystackStoreModule from "@/store/modules/paystack";

const { mapActions: orderActions } = createNamespacedHelpers(
  "CLIENT_MEMBERSHIP_ORDER"
);

const { mapActions: clientActions, mapGetters: clientGetters } =
  createNamespacedHelpers("MEMBERSHIP_CLIENT_LIST");

const { mapActions: paystackActions } = createNamespacedHelpers(
  "PAYSTACK_MEMBERSHIP"
);

export default Vue.extend<any, any, any, any>({
  name: "MembershipProgramForm",
  props: {
    membership: {
      type: Object as PropType<Membership>,
      required: true,
    },
  },
  data: () => ({
    valid: false,
    client: undefined as undefined | Client,
    priceFrequency: null as any,
    loading: false,
    subscribedOn: "",
    expiresOn: "",
    paymentMethod: "card",
    priceFrequencies: [] as any[],
    clientRules: [(v: string) => !!v || "Client field is required"],
    priceFrequencyRules: [
      (v: string) => !!v || "Price frequency field is required",
    ],
    subscribedOnRules: [
      (v: string) => !!v || "Subscribed on field is required",
      (v: string) =>
        DATE_REGEX.test(v) || "Subscribed on field must be valid format",
    ],
    duration: null,
    durationRules: [(v: number) => v > 0 || "Duration field is required"],
    methodRules: [(v: string) => !!v || "Payment method field is required"],
    phone: "",
    phoneRules: [(v: string) => !!v || "Phone field is required"],
  }),
  computed: {
    ...clientGetters(["clientPage", "getClient"]),
    role(): Role {
      return this.$store.getters.role;
    },
    vendor(): Business {
      return this.role.business as Business;
    },
    user(): User {
      return this.$store.getters.user;
    },
    checkoutRedirectURL(): string {
      return location.origin + "/membership";
    },
  },
  created() {
    this.resetForm();
    this.fetchData();

    if (this.membership) {
      this.priceFrequencies = [];
      const data: any[] = Object.entries(this.membership.price).map((item) => {
        return {
          type: item[0],
          price: item[1] || 0,
        };
      });
      this.priceFrequencies = data;
    }
  },
  watch: {
    client() {
      if (this.client) {
        this.phone = this.client.phone;
      }
    },
  },
  methods: {
    ...orderActions(["createOrder"]),
    ...clientActions(["fetchClientList"]),
    ...paystackActions(["initTransaction"]),
    searchClient(q: string) {
      const bid = (this.role.business as Business)._id;
      if (q) this.fetchClientList(`?businessId=${bid}&q=${q}`);
      //TODO: use debounce
      else this.fetchClientList(`?businessId=${bid}`);
    },
    validateForm() {
      const valid = (
        this.$refs.programForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      const client = this.client as Client;

      const payload = {
        clientId: client._id,
        businessId: (client.business as Business)._id,
        cost: this.priceFrequency["price"],
        memberships: [
          {
            membershipId: this.membership?._id,
            priceFrequency: this.priceFrequency["type"],
            duration: this.duration,
            value: this.priceFrequency["price"],
          },
        ],
        payment: {
          method: this.paymentMethod,
          amount: this.priceFrequency["price"],
          currency: "KES",
          source: this.paymentMethod === "card" ? "paystack" : this.phone,
        },
      };

      if (this.paymentMethod === "cash") payload.payment.source = "cash";

      this.createOrder(payload).then(async (order) => {
        if (order) {
          this.$emit("data-saved");
          if (this.paymentMethod === "card") {
            const url = await this.initTransaction({
              amount: order.payment.amount * 100,
              reference: order._id,
              email: order.client.email,
              subaccount: order.business.paystackSubaccountCode,
            });
          }
        }
      });
    },
    resetForm() {
      this.client = undefined;
      this.priceFrequency = "";
      this.subscribedOn = "";
      this.expiresOn = "";
      (
        this.$refs.programForm as Element & {
          resetValidation: () => void;
        }
      )?.resetValidation();
    },
    fetchData() {
      if (this.role) {
        this.fetchClientList(
          `?businessId=${(this.role.business as Business)._id}`
        );
      }
    },
    generateReference() {
      let date = new Date();
      return date.getTime().toString();
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("MEMBERSHIP_CLIENT_LIST")) {
      this.$store.registerModule("MEMBERSHIP_CLIENT_LIST", clientStoreModule);
    }
    if (!this.$store.hasModule("CLIENT_MEMBERSHIP_ORDER")) {
      this.$store.registerModule("CLIENT_MEMBERSHIP_ORDER", orderStoreModule);
    }

    if (!this.$store.hasModule("PAYSTACK_MEMBERSHIP")) {
      this.$store.registerModule("PAYSTACK_MEMBERSHIP", paystackStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("MEMBERSHIP_CLIENT_LIST");
    this.$store.unregisterModule("CLIENT_MEMBERSHIP_ORDER");
    this.$store.unregisterModule("PAYSTACK_MEMBERSHIP");
  },
});
