var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.showClientMembershipDialog),callback:function ($$v) {_vm.showClientMembershipDialog=$$v},expression:"showClientMembershipDialog"}},[(_vm.showClientMembershipDialog)?_c('membership-program-form',{attrs:{"membership":_vm.membership},on:{"data-saved":_vm.closeModal,"closed":_vm.closeModal}}):_vm._e()],1),_c('v-row',[_c('v-col',[_c('h3',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('deselect')}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" "+_vm._s(_vm.membership.name)+" ")],1)])],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"filters"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"filter-field",attrs:{"outlined":"","color":"grey darken-2"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter-variant")]),_c('span',[_c('span',{staticClass:"text-grey"},[_vm._v("Sort by: ")]),_vm._v(" "+_vm._s(_vm.selectedClient)+" ")]),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((['All clients']),function(item){return _c('v-list-item',{key:item,on:{"click":function($event){_vm.selectedClient = item}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)}),1)],1)],1),_c('div',{staticClass:"extra-btns"},[_c('v-btn',{attrs:{"elevation":"0"}},[_c('v-icon',[_vm._v("mdi-export-variant")]),_vm._v(" Export ")],1),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){_vm.showClientMembershipDialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add Client ")],1)],1)])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.clientMembershipPage.docs,"options":_vm.options,"server-items-length":10,"no-data-text":"No memberships to display"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',[_c('img',{attrs:{"src":require("../../assets/avatar.png"),"alt":"Client Image"}})]),_vm._v("   "),(item.client)?_c('span',[_vm._v(_vm._s(item.client.fullName))]):_vm._e()]}},{key:"item.subscribedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.subscribedOn,"D/MM/YYYY"))+" ")]}},{key:"item.expiresOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("readableDate")(item.expiresOn,"D/MM/YYYY"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'active')?_c('v-chip',{attrs:{"color":"#d3f9db"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]):_vm._e(),(item.status === 'expired')?_c('v-chip',{attrs:{"color":"#facccc"}},[_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.status))])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }